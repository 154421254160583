import './styles/index.scss';

import Glide from '@glidejs/glide';
import { $, $$ } from './scripts/common/helpers';

const switcher = $('.radio');
if (switcher) {
  switcher.addEventListener('click', (ev) => {
    switcher.classList.toggle('right');
  });
}

// scrollable links

const links = $$('.ui-scroll-to');
if (links) {
  links.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      const link = event.target as HTMLAnchorElement;
      const id = link.href.slice(link.href.indexOf('#') + 1);

      const element = $(`.ui-scroll-to-target-${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  });
}

//  sliders
(() => {
  // https://glidejs.com/docs/options/
  const glideElement = $('.glide');
  if (!glideElement) {
    return;
  }
  // @ts-ignore
  const glide = new Glide('.glide');
  glide.mount();

  const glidePromotionsElement = $('#promotions');
  if (!glidePromotionsElement) {
    return;
  }
  // @ts-ignore
  const glidePromotions = new Glide('#promotions', {
    type: 'carousel',
    startAt: 0,
    perView: 3,
  });
  glidePromotions.mount();
})();

// components

// checkbox
$$('.checkbox:not(.disableGlobalEvents)').forEach((checkElement: any) => {
  checkElement.addEventListener('click', (ev) => {
    $$('.checkbox[data-name="' + checkElement.dataset.name + '"]').forEach((otherCheckElement) => {
      otherCheckElement.classList.remove('checked');
    });
    checkElement.classList.add('checked');
  });
});
